.continer {
  text-align: center;
  margin: 50px;
  background-color: rgb(64, 64, 71);
  width: 300px;
  height: 490px;
  padding:20px;
  border-radius: 20px;
  /* display: flex;
  justify-content: center;  */
}
.display{
 background-color: azure;
 height: 60px;
 margin-top: -10px;
 border-radius: 5px;
 font-weight: 700;
 text-align: initial;
 border: 10px solid rgba(22, 22, 36, 0.686);

}
.buttons{
  width: 50px;
  height: 50px;
 margin-top: 20px;
 border-radius: 15px;
 padding: 30px;
font-size: medium;
font-weight: 800;
font-style: normal;
}
